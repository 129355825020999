import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import HtmlParser from "react-html-parser";

function Story({data}) {
    return (
        <div className="tekrevol_story tekrevol_gray_bg p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={6} xl={6} xxl={6} className="my-auto">
                        <div className="example" data-text={data?.our_story_body_txt}>
                            <h2>{HtmlParser(checkData(data, 'our_story_title'))}</h2>
                        </div>
                        <div>{HtmlParser(checkData(data, 'our_story_description'))}</div>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={6} xxl={6} className="my-auto">
                        <ul>
                            {data?.our_story_counter?.map((value, i) => (
                                <li>
                                    {checkData(value, 'counter_numbers')}
                                    <p>{checkData(value, 'counter_description')}</p>
                                </li>
                            ))}

                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Story;