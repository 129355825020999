import React, {useEffect, useState} from 'react';
import Banner from "./banner";
import Layout from "../layout/layout";
import Seo from "../layout/seo";
import axios from "axios";
import {baseURL} from "../../utils/constant";
import {checkData} from "../../helper";
import Loader from "../components/Loader";
import Breadcrumbs from "../components/breadcrumbs";
import AboutTek from "./AboutTek";
import Story from "./story";
import Partner from "../home/partner";
import Impact from "./impact";
import BlogSection from "./blog_section";
import Awardsrecognitions from "../home/awardsrecognitions";

function Index(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true);
        const response = await axios.get(`${baseURL}/about-page`);
        setUserData(response.data.data);
        setIsLoaded(false);
    };
    useEffect(() => {
        getGiHubUserWithAxios();

    }, []);

    return (
        <Layout>
            {isLoaded ?
                <Loader/>
                :
                <>
                    <Seo title={checkData(userData, 'meta_title')}
                         description={checkData(userData, 'meta_description')}/>
                    <Breadcrumbs link1="TekRevol" link2="About US"/>
                    <Banner data={userData}/>
                    <AboutTek data={userData}/>
                    <Story data={userData}/>
                    <Awardsrecognitions/>
                    <Impact data={userData}/>
                    <BlogSection/>
                    <Partner/>
                </>
            }
        </Layout>

    );
}

export default Index;

